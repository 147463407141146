
import {
  defineComponent, ref
} from 'vue'
import ServicesNumbersBuyWrapper from '@/components/pages/services/numbers/buy/ServicesNumbersBuyWrapper.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    ServicesNumbersBuyWrapper,
    TmFormLine,
    TmButton,
  },
  props: {
    isEdit: {
      type: Boolean,
    },
  },
  setup() {
    const { openModal } = useModals()

    const forwardCallsToVariant = [
      'Disabled',
      'SMS Chat',
      'Your number',
    ]

    const forwardCallsTo = ref('Your number')

    const forwardCallsNumber = ref('')

    return {
      forwardCallsToVariant,
      forwardCallsTo,
      forwardCallsNumber,
      openModal,
    }
  },
})
